import React, { useEffect, useState } from "react";
import { withI18n } from "~/hocs";
import { NextPage } from "next";
import { AppCommonProps } from "types/app";
import { Modal } from "react-bootstrap";
import moment from "moment-timezone";
import { createStructuredSelector } from "reselect";
import {
  makeSelectIsAuthenticated,
  makeSelectProfile,
} from "~/store/auth/selector";
import { connect } from "react-redux";

const FirstUserLoginModal: NextPage<AppCommonProps> = ({
  onHide,
  isAuthen,
  profile,
}) => {
  const [htmlContent, setHtmlContent] = useState<string>(""); // State lưu HTML từ API
  const [isLoading, setIsLoading] = useState<boolean>(false); // State hiển thị trạng thái tải dữ liệu
  const [show, setShow] = useState<boolean>(false);
  const now = moment().tz("Asia/Tokyo");
  const startDate = moment.tz("2024-11-20 00:00:00", "Asia/Tokyo");
  const endDate = moment.tz("2025-01-06 00:00:00", "Asia/Tokyo");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isWindows = () => {
    return navigator.platform.indexOf('Win') > -1
  }

  useEffect(() => {
    if (isAuthen && profile?.session_id && now.isBetween(startDate, endDate)) {
      const storedData = localStorage.getItem("user_logined_array");
      const currentDate = moment().tz("Asia/Tokyo").format("YYYY-MM-DD");
      const userID = profile?.id;

      if (!storedData) {
        const newUserObject = {
          id: userID,
          date: currentDate,
          is_Stopped: false,
        };
        localStorage.setItem("user_logined_array", JSON.stringify([newUserObject]));
        handleShowModalUserLoginFirst();
      } else {
        try {
          const parsedArray = JSON.parse(storedData);

          if (Array.isArray(parsedArray)) {
            const userObject = parsedArray.find((obj) => obj.id === userID);

            if (!userObject) {
              const newUserObject = {
                id: userID,
                date: currentDate,
                is_Stopped: false,
              };
              parsedArray.push(newUserObject);
              localStorage.setItem("user_logined_array", JSON.stringify(parsedArray));
              handleShowModalUserLoginFirst();
            } else if (!userObject.is_Stopped) {
              if (userObject.date !== currentDate) {
                userObject.date = currentDate;
                localStorage.setItem("user_logined_array", JSON.stringify(parsedArray));
                handleShowModalUserLoginFirst();
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  }, []);

  const handleShowModalUserLoginFirst = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.SSO_SERVER_URL}/get-banner-dac-pr`
      );
      if (!response.ok) {
        handleClose();
      }
      const html = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      if (isWindows()) {
        const box = doc.querySelector(".pr-designac-modal__box")
        box?.classList.add("window-screen");
      }
      const styleTags = Array.from(doc.querySelectorAll("style"));

      const targetSpan = doc.querySelector(".font-weight-bold.color-ff711d.pr-designac-modal__container__main_1-text-main")
      if (targetSpan) {
        targetSpan.textContent = "サムネイル"
      }
      const targetDiv1 = doc.querySelector(".align-items-center.d-flex.flex-wrap.justify-content-center.pr-designac-modal__container__main_1")
      if (targetDiv1) {
        const spans = targetDiv1.getElementsByTagName("span")
        if (spans.length > 0) {
          const firstSpan = spans[0]
          const lastSpan = spans[spans.length - 1]
          const span2 = spans[spans.length - 2]
          firstSpan.textContent = "で"
          firstSpan.style.marginTop = "8px"
          span2.classList.add("ml-2")
          lastSpan.textContent = "を無料でつくろう！"
          lastSpan.classList.add("ml-2")
          lastSpan.style.marginTop = "8px"
        }
      }
      const imgElement = doc.querySelector(".dac-template")
      if (imgElement) {
        imgElement.src = `${process.env.ASSET_PREFIX}/asset/image/video_banner_user_login_quest.webp`
        console.log("Image source updated:", imgElement.src)
      } else {
        console.log("Image element not found.")
      }
      styleTags.forEach((styleTag) => {
        document.head.appendChild(styleTag.cloneNode(true));
      });
      const footerDiv = doc.querySelector(".pr-designac-modal__footer-pc")
      if (footerDiv) {
        footerDiv.className = "ac-px-1 ac-py-3 ac-row align-items-center align-content-center d-flex h-100 w-100 pr-designac-modal__footer-pc"
        footerDiv.innerHTML = `
      <ul class="ac-col-12 ac-col-md-4 ac-list-disc ac-px-2">
        <li class="ac-mb-1"><a href="https://www.design-ac.net/free-template/youtube-thumbnail?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_video-youtube-thumbnail_20241204" class="pr-designac-modal__footer__link" target="_blank">Youtubeサムネイル</a></li>
        <li class="ac-mb-1"><a href="https://www.design-ac.net/free-template/blog-banner?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_video-popup_blog-banner_20241204" class="pr-designac-modal__footer__link" target="_blank">ブログアイキャッチ</a></li>
      </ul>
      <ul class="ac-col-12 ac-col-md-4 ac-list-disc ac-px-2 ml-3">
        <li class="ac-mb-1"><a href="https://www.design-ac.net/templates/search?keyword=%E3%83%81%E3%83%A3%E3%83%B3%E3%83%8D%E3%83%AB%E3%82%A2%E3%83%BC%E3%83%88&utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_video-channel-art_20241204" class="pr-designac-modal__footer__link" target="_blank">チャンネルアイキャッチ</a></li>
        <li class="ac-mb-1"><a href="https://www.design-ac.net/free-template/zoom?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_video-popup_zoom_20241204" class="pr-designac-modal__footer__link" target="_blank">バーチャル背景</a></li>
      </ul>
`
  }
  const footerDivMobile = doc.querySelector(".pr-designac-modal__footer-mb")
  if (footerDivMobile) {
    footerDivMobile.innerHTML = `
    <div class="pr-designac-modal__footer-mb">
          <div class="row-gap-custom ac-mb-2 ac-mb-md-4">
              <div class="ac-d-flex border col-item-3-custom justify-content-center border-radius-10 overflow-hidden">
                  <a href="https://www.design-ac.net/free-template/youtube-thumbnail?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_video-youtube-thumbnail_20241204" class="align-items-center d-flex justify-content-center pr-designac-modal__footer__link w-100 bg-fff" target="_blank">Youtubeサムネイル</a>
              </div>
              <div class="ac-d-flex border col-item-3-custom justify-content-center border-radius-10 overflow-hidden">
                <a href="https://www.design-ac.net/templates/search?keyword=%E3%83%81%E3%83%A3%E3%83%B3%E3%83%8D%E3%83%AB%E3%82%A2%E3%83%BC%E3%83%88&utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_video-channel-art_20241204" class="align-items-center d-flex justify-content-center pr-designac-modal__footer__link w-100 bg-fff" target="_blank">チャンネルアイキャッチ</a>
              </div>
              <div class="ac-d-flex border col-item-3-custom justify-content-center border-radius-10 overflow-hidden">
                  <a href="https://www.design-ac.net/free-template/blog-banner?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_video-popup_blog-banner_20241204" class="align-items-center d-flex justify-content-center pr-designac-modal__footer__link w-100 bg-fff" target="_blank">ブログアイキャッチ</a>
              </div>
              <div class="ac-d-flex border col-item-3-custom justify-content-center border-radius-10 overflow-hidden">
                  <a href="https://www.design-ac.net/free-template/zoom?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_video-popup_zoom_20241204" class="align-items-center d-flex justify-content-center pr-designac-modal__footer__link w-100 bg-fff" target="_blank">バーチャル背景</a>
              </div>
          </div>
      </div>
    `
  }
      // Get the updated HTML content (excluding <head> contents)
      const updatedHTML = doc.body.innerHTML;
      // Ensure the HTML content is set without overwriting the styles
      setHtmlContent(updatedHTML);
      // setHtmlContent(html);
      handleShow();
    } catch (error) {
      console.error("Error fetching HTML content:", error);
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxClick = (event: Event) => {
    const target = event.target as HTMLInputElement
    if (target.id === "checkbox-hidden-dac-pr" && target.type === "checkbox") {
      const storedData = localStorage.getItem('user_logined_array');
      if (storedData) {
        try {
          const parsedArray = JSON.parse(storedData);
          const userObjectIndex = parsedArray.findIndex(obj => obj.id === profile?.id);
    
          if (userObjectIndex !== -1) {
            parsedArray[userObjectIndex].is_Stopped = event.target.checked;
            localStorage.setItem('user_logined_array', JSON.stringify(parsedArray));
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  useEffect(() => {
    // Kiểm tra khi modal hiển thị và nội dung đã tải xong
    if (show && htmlContent) {
      const modalContent = document.querySelector(".modal-content");

      if (modalContent) {
        modalContent.addEventListener("change", handleCheckboxClick)
        modalContent.addEventListener("click", handleContentClick)
      }

      // Cleanup sự kiện khi component unmount hoặc khi modal bị đóng
      return () => {
        if (modalContent) {
          modalContent.removeEventListener("click", handleContentClick)
          modalContent.removeEventListener("change", handleCheckboxClick)
        }
      };
    }
  }, [show, htmlContent]); // Chỉ chạy khi `show` hoặc `htmlContent` thay đổi

  const handleContentClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (
      target.tagName !== "A" &&
      target.tagName !== "INPUT" &&
      target.tagName !== "LABEL" && target.className !=="close-button"
    ) {
      window.open(
        "https://www.design-ac.net?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_logo_20241121",
        "_blank"
      );
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-first-user-login"
      >
        <button
          type="button"
          className="close-button"
          onClick={handleClose}
          aria-label="Close"
        >
          &times;
        </button>
        <Modal.Body>
          {isLoading ? (
            <></>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isAuthen: makeSelectIsAuthenticated(),
  profile: makeSelectProfile(),
});

export default connect(mapStateToProps)(withI18n(FirstUserLoginModal));
